import { ReactNode, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Box, Container, Stack } from '@mui/system'
import { Tab, Tabs, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { isNone, isSome, toNullable } from 'fp-ts/lib/Option'
import { getOrganization, IOrganization, qk } from 'src/api'
import { EnumType } from 'src/lib/types'
import { useIsSessionLoaded } from 'src/lib/use-is-session-loaded'
import { useLocale } from 'src/lib/use-locale'
import { pages } from 'src/app/pages'
import { GradientImage } from 'src/components/gradient-image'
import { organizationHeaderGradient } from 'src/assets/gradients'
import { getCountryNameTranslated } from 'src/lib/country'
import { NextLink } from 'src/components/next-link'
import { formatVatNumber } from 'src/lib/format'
import {
  ResponsiveImage,
  getHeaderImageSrcSet,
} from 'src/components/responsive-image'

export enum OrganizationTab {
  HOME = 'HOME',
  EVENTS = 'EVENTS',
  CLC = 'CLC',
}

type Props = {
  organizationInitialData: IOrganization
  children: ReactNode
  tab: EnumType<OrganizationTab>
}

export const OrganizationLayout = ({
  organizationInitialData,
  children,
  tab,
}: Props) => {
  const { t } = useTranslation()
  const isSessionLoaded = useIsSessionLoaded()
  const { locale } = useLocale()

  const $organization = useQuery(
    qk.auth.public.organization.details.toKeyWithArgs({
      organizationSlug: organizationInitialData.organizationSlug,
    }),
    () =>
      getOrganization({
        organizationSlug: organizationInitialData.organizationSlug,
      }),
    {
      initialData: organizationInitialData,
      enabled: isSessionLoaded,
    },
  )

  const isHideCLC =
    $organization.data !== undefined &&
    isNone($organization.data.customerLoyaltyClub)

  const organizationTabs = useMemo(
    () => [
      {
        label: t('home'),
        value: OrganizationTab.HOME,
        href: pages['/organizations/[organizationSlug]']({
          organizationSlug: organizationInitialData.organizationSlug,
        }),
      },
      {
        label: t('events'),
        value: OrganizationTab.EVENTS,
        href: pages['/organizations/[organizationSlug]/events']({
          organizationSlug: organizationInitialData.organizationSlug,
        }),
      },
      {
        label: t('loyalty_club'),
        value: OrganizationTab.CLC,
        href: pages['/organizations/[organizationSlug]/clc']({
          organizationSlug: organizationInitialData.organizationSlug,
        }),
        isHide: isHideCLC,
      },
    ],
    [isHideCLC, organizationInitialData.organizationSlug, t],
  )

  if ($organization.data === undefined) {
    return null
  }

  const organization = $organization.data

  const logo = (
    <GradientImage
      backgroundColor={toNullable(organization.colorTheme)}
      src={organization.logo.url}
      alt={organization.name}
      gradient={organizationHeaderGradient}
      containerProportion={{ xs: 98, sm: 146, lg: 170, xl: 200 }}
      imageProportion={{ xs: 95, sm: 141, lg: 166, xl: 195 }}
      srcSet={{
        MOBILE: organization.logo.resizedUrls.SMALL,
        TABLET: organization.logo.resizedUrls.MEDIUM,
      }}
    />
  )

  return (
    <Box>
      {isSome(organization.headerImage) ? (
        <Box
          height={{ xs: 163, sm: 225, lg: 308, xl: 372 }}
          position="relative"
        >
          <ResponsiveImage
            hasPriority
            originalSizeUrl={organization.headerImage.value.url}
            alt={organization.name}
            srcSet={getHeaderImageSrcSet(
              organization.headerImage.value.resizedUrls,
            )}
          />
          <Container
            maxWidth="md"
            sx={{
              position: 'absolute',
              top: { xs: 91, sm: 125, lg: 242, xl: 291 },
              left: 0,
              right: 0,
            }}
          >
            {logo}
          </Container>
        </Box>
      ) : (
        <Box
          height={{ xs: 92, sm: 169, lg: 177, xl: 260 }}
          sx={{ background: organizationHeaderGradient, position: 'relative' }}
        >
          <Container
            maxWidth="md"
            sx={{
              position: 'absolute',
              top: { xs: 20, sm: 69, lg: 111, xl: 180 },
              left: 0,
              right: 0,
            }}
          >
            {logo}
          </Container>
        </Box>
      )}
      <Stack
        sx={{
          backgroundColor: 'common.white',
          boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.15)',
        }}
        pt={{ xs: 6, sm: 8.75, lg: 2.25, xl: 4 }}
      >
        <Container maxWidth="md">
          <Stack spacing={{ xs: 1.75, sm: 3, lg: 6.5, xl: 6.75 }}>
            <Stack pl={{ lg: 23.25, xl: 27 }} spacing={{ xs: 0.5, lg: 1 }}>
              <Typography variant="h1">{organization.name}</Typography>
              <Stack
                spacing={{ xs: 0.5, sm: 1 }}
                sx={{ color: 'secondary8.main' }}
                direction={{ sm: 'row' }}
                alignItems={{ sm: 'center' }}
              >
                <Stack direction="row" spacing={{ xs: 1 }} alignItems="center">
                  <Typography variant="smallTextSemiBold">
                    {t('organizer')}
                  </Typography>
                  <Box
                    width={{ xs: 4 }}
                    height={{ xs: 4 }}
                    sx={{
                      backgroundColor: 'secondary8.main',
                      borderRadius: '50%',
                    }}
                  />
                  <Typography variant="smallTextSemiBold">{`${
                    organization.city
                  }, ${getCountryNameTranslated({
                    code: organization.country,
                    locale,
                  })}`}</Typography>
                </Stack>
                <Box
                  width={{ xs: 4 }}
                  height={{ xs: 4 }}
                  sx={{
                    backgroundColor: 'secondary8.main',
                    borderRadius: '50%',
                  }}
                  display={{ xs: 'none', sm: 'block' }}
                />
                <Typography variant="smallTextSemiBold">
                  {t('organization_vat_number', {
                    countryCode: organization.country,
                    vatNumber: formatVatNumber(organization.vatNumber),
                  })}
                </Typography>
              </Stack>
            </Stack>
            <Tabs value={tab}>
              {organizationTabs
                .filter((tab) => !tab.isHide)
                .map((tab) => (
                  <NextLink
                    key={tab.value}
                    variant="h4"
                    label={tab.label}
                    value={tab.value}
                    component={Tab}
                    href={tab.href}
                    underline="none"
                    sx={{
                      '&.Mui-selected': {
                        '&::after': {
                          background: organizationHeaderGradient,
                        },
                      },
                    }}
                  />
                ))}
            </Tabs>
          </Stack>
        </Container>
      </Stack>
      <Container maxWidth="md" sx={{ pt: { xs: 3, sm: 4, lg: 5, xl: 6 } }}>
        {children}
      </Container>
    </Box>
  )
}
