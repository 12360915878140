const getLocalization = (locale: string) =>
  new Intl.DisplayNames([locale], { type: 'region' })

export const getCountryNameTranslated = ({
  code,
  locale,
}: {
  code: string
  locale: string
}) => {
  if (code === '') {
    return ''
  }

  const countryName = getLocalization(locale).of(code)

  if (countryName === undefined) {
    return ''
  }

  return countryName
}
